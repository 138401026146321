<template>
  <v-row>
    <v-col cols="12" v-if="editing" class="pt-0">
      <v-card flat>
        <v-card-title v-text="title" class="text-overline px-0" />

        <v-card-text class="pa-0">
          <v-row>
            <!-- Password -->
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="password"
                :append-icon="icon"
                :type="fieldType"
                @click:append="showPw = !showPw"
                label="Password"
                placeholder="Enter password"
                :loading="loading"
                :error-messages="errors"
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
                autocomplete="new-password"
                counter
                dense
                required
              />
            </v-col>

            <!-- Evaluation -->
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="passwordEval"
                :append-icon="iconEval"
                :type="fieldTypeEval"
                @click:append="showPwEval = !showPwEval"
                label="Confirm password"
                placeholder="Enter password again"
                :loading="loading"
                :error-messages="errorsEval"
                @input="$v.passwordEval.$touch()"
                @blur="$v.passwordEval.$touch()"
                autocomplete="new-password"
                counter
                dense
                required
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import { containsNumeric } from '@/utils/mixins/forms/validators'

export default {
  name: 'pibot-settings-password-new',
  mixins: [validationMixin],
  validations: {
    password: {
      required,
      minLength: minLength(6),
      containsNumeric
    },
    passwordEval: { required, sameAs: sameAs('password') }
  },
  data: () => ({
    title: 'Password',
    showPw: false,
    showPwEval: false,
    password: '',
    passwordEval: ''
  }),
  computed: {
    ...mapState({
      editing: state => state.settings.editing,
      loading: state => state.settings.loading
    }),
    fieldType () {
      return this.showPw ? 'text' : 'password'
    },
    fieldTypeEval () {
      return this.showPwEval ? 'text' : 'password'
    },
    icon () {
      return this.showPw ? 'visibility_off' : 'visibility'
    },
    iconEval () {
      return this.showPwEval ? 'visibility_off' : 'visibility'
    },
    errors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password is required.')
      !this.$v.password.minLength && errors.push('Password needs to have a minimum length of 6.')
      !this.$v.password.containsNumeric && errors.push('Password needs to have a number in it.')
      this.$v.passwordEval.$dirty && !this.$v.passwordEval.sameAs && errors.push('Passwords don\'t match.')
      return errors
    },
    errorsEval () {
      const errors = []
      if (!this.$v.passwordEval.$dirty) return errors
      !this.$v.passwordEval.required && errors.push('Password evaluation is required.')
      this.$v.passwordEval.$dirty && !this.$v.passwordEval.sameAs && errors.push('Passwords don\'t match.')
      return errors
    }
  },
  methods: {
    validate () {
      this.$v.$touch()
      return !this.$v.$invalid
    }
  }
}
</script>
